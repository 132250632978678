/**
 * Customer API
 * Version: 1.0
 * Build: Manual
 */

import {
	ListInfo,
	AcctId,
	DataValidationError,
	Address,
	LatLong,
	MonetaryAmount,
	AuditInfo,
	ActionCd,
	BillTypeCd,
	BillingRemarkTypeCd,
	ChargeToCd,
	CommodityClassCd,
	CommodityPackageCd,
	CustOperationHoursTypeCd,
	CustOperationNoteTypeCd,
	CustomerStatusCd,
	CustomerTypeCd,
	EquipmentSizeCd,
	InvoiceAddressCd,
	InvoiceTypeCd,
	LoadUnloadIdentifierCd,
	LocationOperationTypeCd,
	MatchedPartyStatusCd,
	PartyRoleCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to determine whether the bill-to on the shipment is a restricted bill-to (charges should not be shown).
 */
export class DetermineRestrictedBillToRqst {
	/**
	 * Customer number for the bill-to.
	 * Required: true
	 * Array: false
	 */
	billToCustNbr:number = undefined;
}
/**
 * Response to request to determine whether the bill-to on the shipment is a restricted bill-to (charges should not be shown).
 */
export class DetermineRestrictedBillToResp {
	/**
	 * Indicates whether the bill-to is restricted.
	 * Required: false
	 * Array: false
	 */
	billToIsRestrictedInd:boolean = undefined;
}
/**
 * This request type will be used to retrieve basic account information
 */
export class GetAcctBasicDetailsRqst {
	/**
	 * The database key identifying an account to be retrieved or acted upon
	 * Required: true
	 * Array: false
	 */
	acctInstId:string = undefined;
}
/**
 * Contains basic information related to a place where our customer does business with us. 
 */
export class GetAcctBasicDetailsResp {
	/**
	 * account instance id, mad code, name, partyRole (type) and credit status, address
	 * Required: false
	 * Array: false
	 */
	acctBasicDetails:AcctBasicDetails = undefined;
}
/**
 * This request type will be used to retrieve information from CRM system about an account
 */
export class GetAcctCrmDetailsRqst {
	/**
	 * The database key identifying an account to be retrieved or acted upon
	 * Required: true
	 * Array: false
	 */
	acctInstId:string = undefined;
}
export class GetAcctCrmDetailsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	acctCrmDetail:CRMAccountDetails = undefined;
}
/**
 * Request message to get the Bill To Location details for a given Customer
 */
export class GetAcctBillToLocationDetailRqst {
	/**
	 * The database key identifying an account to be retrieved or acted upon
	 * Required: false
	 * Array: false
	 */
	acctInstId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
}
export class GetAcctBillToLocationDetailResp {
	/**
	 * bill to party details
	 * Required: false
	 * Array: false
	 */
	billTo:AcctBillToDetail = undefined;
}
export class GetCisCustomerHierarchyRqst {
	/**
	 * A meaningful code that is used by users to identify the customer/location.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * The number associated with the legacy CIS customer.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustNbr:number = undefined;
	/**
	 * Identifies whether the query is to retrieve the offspring or the parents.
	 * Required: true
	 * Array: false
	 */
	customerHierarchyType:string = undefined;
}
export class GetCisCustomerHierarchyResp {
	/**
	 * Required: false
	 * Array: true
	 */
	customerHierarchies:CustomerHierarchy[] = undefined;
}
/**
 * Requst to get Location Details for a Customer.
 */
export class GetCustLocationRqst {
	/**
	 * Unique Identifier for the Customer
	 * Required: false
	 * Array: false
	 */
	custAcctId:string = undefined;
}
/**
 * Reponse to request for Location Details for a Customer.
 */
export class GetCustLocationResp {
	/**
	 * Required: false
	 * Array: false
	 */
	custLocation:CustLocation = undefined;
}
/**
 * Request to retrieve a customer's contacts.
 */
export class GetCustomerContactsRqst {
	/**
	 * The number associated with the customer's account.
	 * Required: true
	 * Array: false
	 */
	custAcctNbr:number = undefined;
}
/**
 * Response to the request to retrieve a customer's contacts.
 */
export class GetCustomerContactsResp {
	/**
	 * Customer contact information for pickup, delivery, operations, and NOA.
	 * Required: false
	 * Array: true
	 */
	custContacts:Contact[] = undefined;
}
/**
 * Request message to retrieve details for a Customer Group
 */
export class GetCustomerGroupDetailsRqst {
	/**
	 * Unique Identifier for the customer group.
	 * Required: false
	 * Array: false
	 */
	custGroupInstId:number = undefined;
	/**
	 * The buisiness identifier for the customer group.
	 * Required: false
	 * Array: false
	 */
	groupMadCd:string = undefined;
	/**
	 * The date the customer group became effective.
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
}
/**
 * Response to the request to retrieve details for a Customer Group
 */
export class GetCustomerGroupDetailsResp {
	/**
	 * Required: false
	 * Array: false
	 */
	custGroup:CustomerGroup = undefined;
}
/**
 * Request to retrieve all details for a customer location operations profile using the customer number.
 */
export class GetCustomerLocationOperationsProfileRqst {
	/**
	 * The number associated with the customer's account.
	 * Required: true
	 * Array: false
	 */
	custAcctNbr:number = undefined;
}
/**
 * Response to the request to retrieve all details for a customer location operations profile using the customer number.
 */
export class GetCustomerLocationOperationsProfileResp {
	/**
	 * Contains information related to a place where our customer does business with us.
	 * Required: false
	 * Array: false
	 */
	acctDetail:InterfaceAcct = undefined;
	/**
	 * Details for the customer profile.
	 * Required: false
	 * Array: false
	 */
	custProfile:CustProfile = undefined;
	/**
	 * Details for the customer contact.
	 * Required: false
	 * Array: true
	 */
	custContacts:Contact[] = undefined;
	/**
	 * Details of the customer operation including pickup and delivery options.
	 * Required: false
	 * Array: false
	 */
	customerOperationDetail:CustomerOperationDetail = undefined;
}
/**
 * Request to retrieve customer profile details.
 */
export class GetCustomerProfileRqst {
	/**
	 * The number associated with the customer's account.
	 * Required: true
	 * Array: false
	 */
	custAcctNbr:number = undefined;
	/**
	 * The optional profile type code, which is used as a filter.
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
}
/**
 * Response to the request to retrieve customer profile details.
 */
export class GetCustomerProfileResp {
	/**
	 * Details for the customer profile.
	 * Required: false
	 * Array: false
	 */
	custProfile:CustProfile = undefined;
}
/**
 * Request to retrieve the bill-to related to the input matched pickup and delivery location customer and its invoice type and bill date.
 */
export class GetRelatedBillToForCustomerRqst {
	/**
	 * Unique Identifier for the customer location.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Business identifier for the cusotomer location.
	 * Required: false
	 * Array: false
	 */
	customerMadCd:string = undefined;
	/**
	 * Identifies the type of customer, Pickup Delivery location or Bill-To.
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties.  Values - Prepaid, Collect, Both
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Type of invoice for which these invoicing requirements pertain. This may be original, past due bill, past due statement, corrected or balance due.
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * Bill entry date for the shipment containing the input matched customer and invoice type.
	 * Required: false
	 * Array: false
	 */
	billDate:Date = undefined;
}
/**
 * Response to the request to retrieve the bill-to related to the input matched pickup and delivery location customer and its invoice type and bill date.
 */
export class GetRelatedBillToForCustomerResp {
	/**
	 * Populated if there is a related bill-to customer found for the input pickup delivery customer.
	 * Required: false
	 * Array: false
	 */
	billToCustomer:MatchCustomerBillingDetail = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self), the BILL_TO_LOCATION(bill-to) or from the information entered on the bill (as billed).
	 * Required: false
	 * Array: false
	 */
	invoiceAddressCd:InvoiceAddressCd = undefined;
	/**
	 * Status returned from customer matching for the bill-to
	 * Required: false
	 * Array: false
	 */
	billToMatchedPartyStatusCd:MatchedPartyStatusCd = undefined;
}
/**
 * Request to retrieve the bill-to related to the input matched pickup and delivery location customer and its invoice type and bill date.
 */
export class GetSendToPartyPaymentTermRqst {
	/**
	 * Unique Identifier for the customer location.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Business identifier for the cusotomer location.
	 * Required: false
	 * Array: false
	 */
	customerMadCd:string = undefined;
	/**
	 * Identifies the type of customer, Pickup Delivery location or Bill-To.
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties.  Values - Prepaid, Collect, Both
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Type of invoice for which these invoicing requirements pertain. This may be original, past due bill, past due statement, corrected or balance due.
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * Bill entry date for the shipment containing the input matched customer and invoice type.
	 * Required: false
	 * Array: false
	 */
	billDate:Date = undefined;
}
/**
 * Response to the request to retrieve the bill-to related to the input matched pickup and delivery location customer and its invoice type and bill date.
 */
export class GetSendToPartyPaymentTermResp {
	/**
	 * Populated if there is a related bill-to customer found for the input pickup delivery customer.
	 * Required: false
	 * Array: false
	 */
	billToCustomer:MatchCustomerBillingDetail = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self), the BILL_TO_LOCATION(bill-to) or from the information entered on the bill (as billed).
	 * Required: false
	 * Array: false
	 */
	invoiceAddressCd:InvoiceAddressCd = undefined;
	/**
	 * Status returned from customer matching for the bill-to
	 * Required: false
	 * Array: false
	 */
	billToMatchedPartyStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	paymentTermCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isSvcRecipientInd:boolean = undefined;
}
/**
 * This request type will be used to retrieve basic idenitfying customer location information for the children of a corporate location
 */
export class ListAcctBasicDetailsRqst {
	/**
	 * The database key identifying a customer location record to be retrieved or acted upon
	 * Required: false
	 * Array: true
	 */
	acctInstId:string[] = undefined;
	/**
	 * customer name
	 * Required: false
	 * Array: false
	 */
	acctName:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	acctPartyRole:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Key identifying information for the child of a corporate location
 */
export class ListAcctBasicDetailsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	detailsByAcct:AcctBasicDetails[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * This request type will be used to retrieve basic identifying customer account information for the children of a corporate location
 */
export class ListCorpCustAcctHierarchyChildrenRqst {
	/**
	 * The database key identifying a customer location record to be retrieved or acted upon
	 * Required: true
	 * Array: false
	 */
	acctInstId:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	acctPartyRole:string[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Key identifying information for the child of a corporate location
 */
export class ListCorpCustAcctHierarchyChildrenResp {
	/**
	 * Required: false
	 * Array: true
	 */
	corpAcctChild:AcctBasicDetails[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Requst to list common bol details for customers on a bill.
 */
export class ListCustCommonBolsRqst {
	/**
	 * Unique Identifier for the Shipper  Customer.
	 * Required: false
	 * Array: false
	 */
	shipperCustNbr:number = undefined;
	/**
	 * Unique Identifier for the Bill-To Customer.
	 * Required: false
	 * Array: false
	 */
	consigneeCustNbr:number = undefined;
	/**
	 * Unique Identifier for the Consignee Customer.
	 * Required: false
	 * Array: false
	 */
	billToCustNbr:number = undefined;
	/**
	 * Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * Valid Values : 
	 * P    -  Prepaid - shipper responsible for all charges
	 * C    -  Collect - consignee responsible for all charges
	 * B    -  Both - shipper and consignee responsible 
	 * -  Valid only for Non-Revenue bills.
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Date that will be used in selecting effective SRN rules. Rules that are effective on this date will be returned, if they meet the other criteria.
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Reponse to requst to list common bol details for customers on a bill.
 */
export class ListCustCommonBolsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	custCommonCommodities:CustCommonCommodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	custBillingRemarks:BillingRemark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	custSuppRefRules:SuppRefRule[] = undefined;
	/**
	 * Indicates whether the charge to cd on the shipment was flipped based on the debtor relationships.
	 * Required: false
	 * Array: false
	 */
	chargeToCdFlippedInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve a list of Customer Groups for a given location
 */
export class ListCustomerGroupsForLocationRqst {
	/**
	 * Unique Identifier for the customer location ID/MAD.
	 * Required: true
	 * Array: false
	 */
	customerNbr:string = undefined;
	/**
	 * CIS Customer type code (optional): P(PickupDelivery), C(Corporate), B(BillTo), S(ServiceRecipient)
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:CustomerTypeCd = undefined;
	/**
	 * CIS Group Type Code: Collector(C), EDI(E)
	 * Required: true
	 * Array: false
	 */
	groupType:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListCustomerGroupsForLocationResp {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	custGroup:CustomerGroup[] = undefined;
}
/**
 * List the invoice types and their invoice address for the input customer and type
 */
export class ListCustomerInvcTypeAddressesRqst {
	/**
	 * Unique identifier for the customer
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
}
/**
 * Return the invoice types and their invoice address for the input customer and type
 */
export class ListCustomerInvcTypeAddressesResp {
	/**
	 * Customer Invoice Types
	 * Required: false
	 * Array: true
	 */
	customerInvoiceTypes:CustInvoiceTypeDetail[] = undefined;
}
/**
 * This request type will be used to retrieve a list of profile codes related to the type submitted
 */
export class ListCustProfileCodeByTypeRqst {
	/**
	 * The code identifying a set of related codes in the profile code table
	 * Required: true
	 * Array: false
	 */
	profileTypeCd:string = undefined;
}
/**
 * Based on a particular profile code type, the list of codes will be returned.
 */
export class ListCustProfileCodeByTypeResp {
	/**
	 * The list of codes related to the  code type that was submitted.
	 * Required: false
	 * Array: true
	 */
	profileCodes:CustProfileCd[] = undefined;
}
/**
 * This request type will be used to retrieve a list of profile codes related to the type submitted
 */
export class MaintainCustProfileRqst {
	/**
	 * The code identifying a set of related codes in the profile code table
	 * Required: true
	 * Array: false
	 */
	profileTypeCd:CustProfile = undefined;
}
/**
 * Response to match requst for one or more parties for a shipment to customers.
 */
export class MatchCustomerResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shipperId:AcctId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperLocation:CustLocation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperAddrValidationCd:DataValidationError = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeId:AcctId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeLocation:CustLocation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeAddrValidationCd:DataValidationError = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToId:AcctId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToLocation:CustLocation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToAddrValidationCd:DataValidationError = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipperCustomerMatchInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerMatchInd:boolean = undefined;
	/**
	 * P(Pickup and Delivery), F(Future Customer), B(BillTo)
	 * Required: false
	 * Array: false
	 */
	shipperCustomerType:string = undefined;
	/**
	 * P(Pickup and Delivery), F(Future Customer), B(BillTo)
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerType:string = undefined;
}
/**
 * Request to match one or more parties for a shipment to customers.
 */
export class MatchCustomerRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	shipperAddress:Address = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createFutureCustForShipper:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	consigneeAddress:Address = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createFutureCustForConsignee:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	billToAddress:Address = undefined;
}
/**
 * Request to match one or more parties for a shipment to customers for billing.
 */
export class MatchCustomerBillingRqst {
	/**
	 * Billing address for the Shipper.
	 * Required: false
	 * Array: false
	 */
	shipperAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Billing address for the Consignee
	 * Required: false
	 * Array: false
	 */
	consigneeAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Billing address for the Bill-To
	 * Required: false
	 * Array: false
	 */
	billToAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties.  Values - Prepaid, Collect, Both
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Percentage value that will be used as a match score threshold, where only possible matches greater than the threshold will be returned in the response.
	 * Required: false
	 * Array: false
	 */
	matchScoreThresholdPercentage:number = undefined;
}
/**
 * Response to match requst for one or more parties for a shipment to customers for Billing.
 */
export class MatchCustomerBillingResp {
	/**
	 * Populated if the input shipper address is adjusted in address validation.
	 * Required: false
	 * Array: false
	 */
	shipperAdjustedAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Populated if the input consignee address is adjusted in address validation.
	 * Required: false
	 * Array: false
	 */
	consigneeAdjustedAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Populated if the input Bill-To address is adjusted in address validation.
	 * Required: false
	 * Array: false
	 */
	billToAdjustedAddress:MatchCustomerBillingDetail = undefined;
	/**
	 * Full match data for Shipper.
	 * Required: false
	 * Array: false
	 */
	shipperFullMatch:MatchCustomerBillingDetail = undefined;
	/**
	 * Full match data for Consignee
	 * Required: false
	 * Array: false
	 */
	consigneeFullMatch:MatchCustomerBillingDetail = undefined;
	/**
	 * Full match data for Bill-To
	 * Required: false
	 * Array: false
	 */
	billToFullMatch:MatchCustomerBillingDetail = undefined;
	/**
	 * Possible matches for shipper name and address out of customer matching.
	 * Required: false
	 * Array: true
	 */
	shipperPossibleMatches:MatchCustomerBillingDetail[] = undefined;
	/**
	 * Possible matches for consginee name and address out of customer matching.
	 * Required: false
	 * Array: true
	 */
	consigneePossibleMatches:MatchCustomerBillingDetail[] = undefined;
	/**
	 * Possible matches for bill-to name and address out of customer matching.
	 * Required: false
	 * Array: true
	 */
	billToPossibleMatches:MatchCustomerBillingDetail[] = undefined;
	/**
	 * Shipper address validation hard errors.
	 * Required: false
	 * Array: true
	 */
	shipperValidationErrors:DataValidationError[] = undefined;
	/**
	 * Consignee address validation hard errors.
	 * Required: false
	 * Array: true
	 */
	consigneeValidationErrors:DataValidationError[] = undefined;
	/**
	 * Bill-To address validation hard errors.
	 * Required: false
	 * Array: true
	 */
	billToValidationErrors:DataValidationError[] = undefined;
	/**
	 * Indicates that the input shipper address is a validated and certified Postal address
	 * Required: false
	 * Array: false
	 */
	shipperAddressValidatedInd:boolean = undefined;
	/**
	 * Indicates that one or more of the shipper address input fields has been revised by the vendor address validation software
	 * Required: false
	 * Array: false
	 */
	shipperFieldsRevisedInd:boolean = undefined;
	/**
	 * Indicates that non-vendor software related errors were encountered when validating the input shipper address
	 * Required: false
	 * Array: false
	 */
	shipperHardErrorsFoundInd:boolean = undefined;
	/**
	 * Indicates that one or more of the 1-25 returned return/reason codes returned from the software validation are are negative, for the input shipper address
	 * Required: false
	 * Array: false
	 */
	shipperVendorValidationErrorInd:boolean = undefined;
	/**
	 * Indicates that the input consignee address is a validated and certified Postal address
	 * Required: false
	 * Array: false
	 */
	consigneeAddressValidatedInd:boolean = undefined;
	/**
	 * Indicates that one or more of the consignee address input fields has been revised by the vendor address validation software
	 * Required: false
	 * Array: false
	 */
	consigneeFieldsRevisedInd:boolean = undefined;
	/**
	 * Indicates that non-vendor software related errors were encountered when validating the input consignee address
	 * Required: false
	 * Array: false
	 */
	consigneeHardErrorsFoundInd:boolean = undefined;
	/**
	 * Indicates that one or more of the 1-25 returned return/reason codes returned from the software validation are are negative, for the input consignee address
	 * Required: false
	 * Array: false
	 */
	consigneeVendorValidationErrorInd:boolean = undefined;
	/**
	 * Indicates that the input bill-to address is a validated and certified Postal address
	 * Required: false
	 * Array: false
	 */
	billToAddressValidatedInd:boolean = undefined;
	/**
	 * Indicates that one or more of the bill-to address input fields has been revised by the vendor address validation software
	 * Required: false
	 * Array: false
	 */
	billToFieldsRevisedInd:boolean = undefined;
	/**
	 * Indicates that non-vendor software related errors were encountered when validating the input bill-to address
	 * Required: false
	 * Array: false
	 */
	billToHardErrorsFoundInd:boolean = undefined;
	/**
	 * Indicates that one or more of the 1-25 returned return/reason codes returned from the software validation are are negative, for the input bill-to address
	 * Required: false
	 * Array: false
	 */
	billToVendorValidationErrorInd:boolean = undefined;
	/**
	 * Status returned from customer matching for the shipper.
	 * Required: false
	 * Array: false
	 */
	shipperMatchedPartyStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Status returned from customer matching for the consignee
	 * Required: false
	 * Array: false
	 */
	consigneeMatchedPartyStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Status returned from customer matching for the bill-to
	 * Required: false
	 * Array: false
	 */
	billToMatchedPartyStatusCd:MatchedPartyStatusCd = undefined;
}
/**
 * Request to update customer contacts in CIS.
 */
export class UpdateCisCustomerContactsRqst {
	/**
	 * The unique identifier associated with a customer's operational profile.
	 * Required: true
	 * Array: false
	 */
	customerAcctId:number = undefined;
	/**
	 * The list of customer contact information for pickup, delivery, operations, and NOA.
	 * Required: true
	 * Array: true
	 */
	custContacts:Contact[] = undefined;
}
/**
 * Request to update a customer profile in CIS.
 */
export class UpdateCisCustomerProfileRqst {
	/**
	 * The unique identifier associated with a customer's operational profile.
	 * Required: true
	 * Array: false
	 */
	customerAcctId:number = undefined;
	/**
	 * Details about the customer profile.
	 * Required: true
	 * Array: false
	 */
	customerOperationsProfile:CustomerOperationsProfile = undefined;
	/**
	 * The list of profile-related notes about a customer.
	 * Required: true
	 * Array: true
	 */
	customerOperationsNotes:CustomerOperationsNote[] = undefined;
	/**
	 * The list of profile-related schedules for a customer.
	 * Required: true
	 * Array: true
	 */
	customerOperationsSchedules:CustomerOperationsSchedule[] = undefined;
}
/**
 * Request to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustRqst {
	/**
	 * The unique identifier of the customer. ï¿½The customer could be a Future or CIS customer.
	 * Required: true
	 * Array: false
	 */
	customerId:string = undefined;
	/**
	 * Indicates if the customer is a CIS customer.
	 * Required: false
	 * Array: false
	 */
	cisCustomerInd:boolean = undefined;
	/**
	 * The unique identifier for the area associated with the customer.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * The geo coordinates where the customer is located.
	 * Required: true
	 * Array: false
	 */
	geoCoordinate:LatLong = undefined;
}
/**
 * Response to the request to update the geo coordinates for a customer.
 */
export class UpdateGeoCoordinatesForCustResp {
	/**
	 * Indicates if the update was successful.
	 * Required: false
	 * Array: false
	 */
	successInd:boolean = undefined;
}
/**
 * Request message to create SRN rules
 */
export class CreateSRNRulesRqst {
	/**
	 * The SRN Rule
	 * Required: false
	 * Array: false
	 */
	srnRuleInfo:SuppRefRule = undefined;
}
export class CreateSRNRulesResp {
	/**
	 * Unique Id for the Srn Rule.
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
/**
 * Request message to delete SRN Rules
 */
export class DeleteSRNRulesRqst {
	/**
	 * The instance ID of the SRN rule to be deleted.
	 * Required: true
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
export class DeleteSRNRulesResp {
	/**
	 * The instance ID of the SRN rule that was deleted.
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
/**
 * Request for SRN Rule details.
 */
export class GetSRNRuleRqst {
	/**
	 * Unique Id for the Srn Rule.
	 * Required: true
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
/**
 * Response to the request for SRN Rule details.
 */
export class GetSRNRuleResp {
	/**
	 * Details for one SRN Rule
	 * Required: false
	 * Array: false
	 */
	srnRuleInfo:SuppRefRule = undefined;
}
/**
 * List SRN rules
 */
export class ListSRNRulesRqst {
	/**
	 * The customer to whom the SRN rules belong.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * The customer group to which the SRN rules belong.
	 * Required: false
	 * Array: false
	 */
	customerGroupInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListSRNRulesResp {
	/**
	 * The SRN Rule
	 * Required: false
	 * Array: true
	 */
	suppRefRulesList:SuppRefRule[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to update SRN rules
 */
export class UpdateSRNRulesRqst {
	/**
	 * The SRN Rule
	 * Required: false
	 * Array: false
	 */
	srnRuleInfo:SuppRefRule = undefined;
	/**
	 * Unique Id for the Srn Rule.
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
export class UpdateSRNRulesResp {
	/**
	 * Unique ID for the SRN rule
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
}
/**
 * Request message to create billing remarks/instructions
 */
export class CreateBillingRemarksRqst {
	/**
	 * The billing remarks/instructions
	 * Required: false
	 * Array: false
	 */
	billingRemarksInfo:BillingRemark = undefined;
}
export class CreateBillingRemarksResp {
	/**
	 * CUST# from CIS. Can be Pickup dlvr or Bill-to type
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * There are two categories of remarks that are accessed from this entity when Biller matches party on the bill.
	 * 
	 * The first category is for Biller to help correctly enter the bill.
	 * The valid values are :
	 * S - Instructions to Biller for Shipper
	 * C - Instructions to Biller for Consignee
	 * B - Instructions to Biller for Bill-to
	 * 
	 * The second category of remarks are accesssed from this entity and stored on the shipment database the bill being entered in SHM_REMARKS table.
	 * The valid values are:
	 * 
	 * 
	 * H- Instruction to Operations when Hazmat is transported, 
	 * O- Instruction for Pickup and Delivery
	 * Required: false
	 * Array: false
	 */
	remarkTypeCd:BillingRemarkTypeCd = undefined;
}
/**
 * Request message to delete billing remarks/instructions
 */
export class DeleteBillingRemarksRqst {
	/**
	 * The customer number from CIS. Can be Pickup Dlvr or Bill-to type.
	 * Required: true
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * There are two categories of remarks that are accessed from this entity when Biller matches party on the bill.    The first category is for Biller to help correctly enter the bill.  The valid values are:                    S - Instructions to Biller for Shipper,                                C - Instructions to Biller for Consignee, and                      B - Instructions to Biller for Bill-to.                                     The second category of remarks are accesssed from this entity and stored on the shipment database the bill being entered in SHM_REMARKS table.  The valid values are:      H- Instruction to Operations when Hazmat is transported, and                                    O- Instruction for Pickup and Delivery
	 * Required: true
	 * Array: false
	 */
	remarkTypeCd:BillingRemarkTypeCd = undefined;
}
/**
 * This is the request to retrieve a billing remark.
 */
export class GetBillingRemarkRqst {
	/**
	 * Unique identifier for the customer that owns the billing remark.
	 * Required: true
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * There are two categories of remarks that are accessed from this entity when Biller matches party on the bill.    The first category is for Biller to help correctly enter the bill.  The valid values are:                    S - Instructions to Biller for Shipper,                                C - Instructions to Biller for Consignee, and                      B - Instructions to Biller for Bill-to.                                     The second category of remarks are accesssed from this entity and stored on the shipment database the bill being entered in SHM_REMARKS table.  The valid values are:      H- Instruction to Operations when Hazmat is transported, and                                    O- Instruction for Pickup and Delivery
	 * Required: true
	 * Array: false
	 */
	remarkTypeCd:BillingRemarkTypeCd = undefined;
}
/**
 * This is the response for retrieving a billing remark.
 */
export class GetBillingRemarkResp {
	/**
	 * Required: false
	 * Array: false
	 */
	billingRemark:BillingRemark = undefined;
}
/**
 * List billing remarks
 */
export class ListBillingRemarksRqst {
	/**
	 * The customer to whom the billing remarks belong.
	 * Required: true
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListBillingRemarksResp {
	/**
	 * List of billing remarks for a specific customer number.
	 * Required: false
	 * Array: true
	 */
	billingRemarksList:BillingRemark[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to update billing remarks/instructions
 */
export class UpdateBillingRemarksRqst {
	/**
	 * The billing remarks/instructions
	 * Required: false
	 * Array: false
	 */
	billingRemarksInfo:BillingRemark = undefined;
	/**
	 * CUST# from CIS. Can be Pickup dlvr or Bill-to type
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * There are two categories of remarks that are accessed from this entity when Biller matches party on the bill.
	 * The first category is for Biller to help correctly enter the bill.
	 * The valid values are :
	 * S - Instructions to Biller for Shipper
	 * C - Instructions to Biller for Consignee
	 * B - Instructions to Biller for Bill-to
	 * The second category of remarks are accesssed from this entity and stored on the shipment database the bill being entered in SHM_REMARKS table.
	 * The valid values are:
	 * H- Instruction to Operations when Hazmat is transported, 
	 * O- Instruction for Pickup and Delivery
	 * Required: false
	 * Array: false
	 */
	remarkTypeCd:BillingRemarkTypeCd = undefined;
}
/**
 * This is the response to updating the billing remark.
 */
export class UpdateBillingRemarksResp {
	/**
	 * The updated Billing remark
	 * Required: false
	 * Array: false
	 */
	billingRemark:BillingRemark = undefined;
}
/**
 * Request to upsert multiple billing remarks.
 */
export class UpsertMultipleBillingRemarksRqst {
	/**
	 * The list of billing remarks records that will be upserted.
	 * Required: true
	 * Array: true
	 */
	billingRemarks:BillingRemark[] = undefined;
}
/**
 * Response to the request to upsert multiple billing remarks.
 */
export class UpsertMultipleBillingRemarksResp {
	/**
	 * Details about the mass upsert of billing marks.
	 * Required: false
	 * Array: true
	 */
	bulkBillingRemarkUpsertDetails:BulkBillingRemarkUpsertDetail[] = undefined;
}
/**
 * Request to retrieve a shipper dimension profile.
 */
export class GetShipperDimensionProfileRqst {
	/**
	 * The identifier of the shipper dimension profile.
	 * Required: true
	 * Array: false
	 */
	shipperId:number = undefined;
	/**
	 * The profile category code.
	 * Required: false
	 * Array: false
	 */
	profileCategoryCd:string = undefined;
	/**
	 * The profile effective date.
	 * Required: false
	 * Array: false
	 */
	effectiveDate:string = undefined;
	/**
	 * The significant indicator.
	 * Required: false
	 * Array: false
	 */
	significantInd:boolean = undefined;
	/**
	 * The staring destination postal code.
	 * Required: false
	 * Array: false
	 */
	startingDestinationZip:string = undefined;
}
/**
 * Response to the request to retrieve a shipper dimension profile.
 */
export class GetShipperDimensionProfileResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipperProfile:ShipperProfile[] = undefined;
}
/**
 * Request to retrieve a shipper dimension profile.
 */
export class GetShipperProfileFreightCubeRqst {
	/**
	 * The identifier of the shipper dimension profile.
	 * Required: true
	 * Array: false
	 */
	shipperId:number = undefined;
	/**
	 * The destination postal code.
	 * Required: false
	 * Array: false
	 */
	destinationZip:string = undefined;
	/**
	 * The origin terminal SIC code.
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * The profile category code.
	 * Required: false
	 * Array: false
	 */
	profileCategoryCd:string = undefined;
	/**
	 * The profile effective date.
	 * Required: false
	 * Array: false
	 */
	effectiveDate:string = undefined;
	/**
	 * The total pieces.
	 * Required: false
	 * Array: false
	 */
	totalPieces:number = undefined;
	/**
	 * The total weight.
	 * Required: false
	 * Array: false
	 */
	totalWeight:number = undefined;
	/**
	 * The shipper's freight dimensions.
	 * Required: false
	 * Array: true
	 */
	shipperFreightDimensions:ShipperFreightDimension[] = undefined;
}
/**
 * Response to the request to retrieve a shipper dimension profile.
 */
export class GetShipperProfileFreightCubeResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shipperProfile:ShipperProfile = undefined;
}
/**
 * Request to retrieve information about a customer stop.
 */
export class GetCustomerStopDetailRqst {
	/**
	 * The identifier of the customer record.
	 * Required: true
	 * Array: false
	 */
	customerNbr:number = undefined;
}
/**
 * Response to the request to retrieve information about a customer stop.
 */
export class GetCustomerStopDetailResp {
	/**
	 * Required: false
	 * Array: false
	 */
	customerStop:CustomerStop = undefined;
}
/**
 * Bill To Party details
 */
export class AcctBillToDetail {
	/**
	 * account instance id, mad code, name, partyRole (type) and credit status
	 * Required: false
	 * Array: false
	 */
	acctDetail:AcctBillToDetail_AcctId = undefined;
	/**
	 * address information
	 * Required: false
	 * Array: false
	 */
	acctNameAddr:Address = undefined;
}
/**
 * Contains basic information related to a place where our customer does business with us. 
 */
export class AcctBasicDetails {
	/**
	 * account instance id, mad code, name, partyRole (type) and credit status
	 * Required: false
	 * Array: false
	 */
	acctIdInfo:AcctId = undefined;
	/**
	 * address information
	 * Required: false
	 * Array: false
	 */
	acctNameAddr:Address = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	corpAcctChild:AcctBasicDetails[] = undefined;
}
export class BulkBillingRemarkUpsertDetail {
	/**
	 * The number associated with the customer's account.
	 * Required: false
	 * Array: false
	 */
	billingRemark:BillingRemark = undefined;
	/**
	 * The earliest time of day that the customer will be ready to do business.
	 * Required: false
	 * Array: false
	 */
	successInd:boolean = undefined;
	/**
	 * The latest time of day that the customer will be available to do business.
	 * Required: false
	 * Array: false
	 */
	message:string = undefined;
}
/**
 * Contains information about a customer account in the CRM(Salesforce) system
 */
export class CRMAccountDetails {
	/**
	 * Required: false
	 * Array: false
	 */
	accountOwner:CRMAccountOwnerDetail = undefined;
	/**
	 * Details of the Parent Account
	 * Required: false
	 * Array: false
	 */
	parent:AcctId = undefined;
	/**
	 * Details of the Ultimate Parent Account
	 * Required: false
	 * Array: false
	 */
	ultimateParent:AcctId = undefined;
}
/**
 * Contains information about the owner of CIS account in the CRM (Salesforce) system. 
 */
export class CRMAccountOwnerDetail {
	/**
	 * Unique identifier of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * first name and last name of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * job title of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	title:string = undefined;
	/**
	 * profile name of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	profileName:string = undefined;
	/**
	 * Role name of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * Region Code of the XPO employee who owns the account
	 * Required: false
	 * Array: false
	 */
	regionCd:string = undefined;
}
/**
 * The geo location details fo rthe customer.
 */
export class CustInvoiceTypeDetail {
	/**
	 * Used by Invoicing depending on the type of invoice to be sent. 
	 * Valid Values
	 * O - Original Invoice. 
	 * B - Balance Due invoice.  
	 * C - Correct  invoice.
	 * Required: false
	 * Array: false
	 */
	invoiceTypeCd:InvoiceTypeCd = undefined;
	/**
	 * This is used by invoicing to select the preference based on the attriibutes of the shipment. 
	 * Shipment is either billed to third party else it is either a prepaid or collect.
	 * In OMS this was called Debtor_type.
	 * Valid values
	 * PREPAID    - Prepaid
	 * COLLECT    - Collect
	 * 3P               - 3rd Party
	 * Required: false
	 * Array: false
	 */
	billTypeCd:BillTypeCd = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	customerMadCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	invoiceCustomerAddress:Address = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self), the BILL_TO_LOCATION(bill-to) or from the information entered on the bill (as billed).
	 * Required: false
	 * Array: false
	 */
	invoiceAddressCd:InvoiceAddressCd = undefined;
	/**
	 * Date the invoice type became effective
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
}
/**
 * The geo location details fo rthe customer.
 */
export class CustLocation {
	/**
	 * Unique Id for the Customer.
	 * Required: false
	 * Array: false
	 */
	custAcctId:string = undefined;
	/**
	 * Type of the customer location - PND or BillTo or Coporate.
	 * Required: false
	 * Array: false
	 */
	custLocationType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custAddr:Address = undefined;
	/**
	 * The instance Id of the Geo Area for the customer location.
	 * Required: false
	 * Array: false
	 */
	geoAreainstId:string = undefined;
	/**
	 * The latitude for the customer location
	 * Required: false
	 * Array: false
	 */
	custLatitude:number = undefined;
	/**
	 * The longitude for the customer location.
	 * Required: false
	 * Array: false
	 */
	custLongitude:number = undefined;
}
/**
 * Information about customer group
 */
export class CustomerGroup {
	/**
	 * Unique DB identifier of Customer Group
	 * Required: false
	 * Array: false
	 */
	custGroupInstId:string = undefined;
	/**
	 * Name of the group
	 * Required: false
	 * Array: false
	 */
	groupName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	groupType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	expiryDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	groupMadCd:string = undefined;
}
export class CustomerHierarchy {
	/**
	 * A meanigful code that is used by users to identify  the customer/location.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * The party name for the customer/location.
	 * Required: false
	 * Array: false
	 */
	partyName:string = undefined;
	/**
	 * The party name2 for the customer/location.
	 * Required: false
	 * Array: false
	 */
	partyName2:string = undefined;
	/**
	 * The legacy customer number.
	 * Required: false
	 * Array: false
	 */
	legacyCisCustNbr:number = undefined;
	/**
	 * Identifies the type of customer.
	 * Required: false
	 * Array: false
	 */
	functionCd:CustomerTypeCd = undefined;
	/**
	 * The customer's status.
	 * Required: false
	 * Array: false
	 */
	statusCd:CustomerStatusCd = undefined;
	/**
	 * The customer's status.
	 * Required: false
	 * Array: false
	 */
	address:Address = undefined;
	/**
	 * The identifier of the customer or location.
	 * Required: false
	 * Array: false
	 */
	customerLocationFuncId:number = undefined;
	/**
	 * The identifier for the parent customer or location.
	 * Required: false
	 * Array: false
	 */
	parentCustomerLocationFuncId:number = undefined;
	/**
	 * The sequence number that provides order to the location in the response.
	 * Required: false
	 * Array: false
	 */
	displayOrderSeq:number = undefined;
	/**
	 * The level of the tree in which the location is present.
	 * Required: false
	 * Array: false
	 */
	levelNbr:number = undefined;
}
export class CustomerOperationDetail {
	/**
	 * The number associated with the customer's account.
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * The earliest time of day that the customer will be ready to do business.
	 * Required: false
	 * Array: false
	 */
	custOperationReadyTm:Date = undefined;
	/**
	 * The latest time of day that the customer will be available to do business.
	 * Required: false
	 * Array: false
	 */
	custOperationCloseTm:Date = undefined;
	/**
	 * Free form comment used to indicate the limits that may exist at a customer site. E.g. the type of dock, type of equipment required, etc.
	 * Required: false
	 * Array: false
	 */
	operationRemark:string = undefined;
	/**
	 * This indicates that a pickup should be made each day and that a request will not be made.
	 * Required: false
	 * Array: false
	 */
	customerDailyStopInd:boolean = undefined;
	/**
	 * This indicates whether or not to notify customer before delivering.
	 * Required: false
	 * Array: false
	 */
	notifyCustomerOnArrivalInd:boolean = undefined;
	/**
	 * This indicates that the customer will sign a delivery manifest rather than individual delivery receipts.
	 * Required: false
	 * Array: false
	 */
	manifestInLieuOfDeliveryReceiptInd:boolean = undefined;
}
/**
 * The customer profile contains a variety of codes with values representing characteristics of the customer. These characteristics provide information related to Sales, Operations, Special Handling, Physical Location etc. Examples: 
 * Offshore location
 * Outbound Rev Potential
 * Inbound Revenue Potential
 * URL
 * Operations Info
 * Inbound Lane
 * Outbound Lanes
 * Rate Sheet
 * etc.
 * 
 */
export class CustProfile {
	/**
	 * Customer account instance ID
	 * Required: true
	 * Array: false
	 */
	acctInstId:string = undefined;
	/**
	 * True if customer is still a potential customer and not fully a CIS customer.
	 * Required: false
	 * Array: false
	 */
	futureCustInd:boolean = undefined;
	/**
	 * List of profile codes with their values to be acted upon.
	 * Required: false
	 * Array: true
	 */
	profileCodes:CustProfileCd[] = undefined;
	/**
	 * The network userId of the user that is adding or updating elements of the customers profile.
	 * Required: true
	 * Array: false
	 */
	lastUpdateBy:string = undefined;
}
/**
 * The customer profile code represents a characteristic of a customer. In a profile there may be many of them, some of them have values associated unique to the customer. These characteristics provide information related to Sales, Operations, Special Handling, Physical Location etc. Examples: 
 * Offshore location
 * Outbound Rev Potential
 * Inbound Revenue Potential
 * URL
 * Operations Info
 * Inbound Lane
 * Outbound Lanes
 * Rate Sheet
 * etc.
 * 
 */
export class CustProfileCd {
	/**
	 * valid values create, update, delete
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * A code identifying a set of related characteristics to be tracked
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * Type of note. Holds profile information for the customer AIM would like to track.
	 * All customer may not have all profile information and each customer may have specific value for specific profile information.
	 * Corresponds to CODE_ID in CIS_CODES_AIM table with CODE_TYP_CD representing Customer Profile.
	 * 
	 * Examples:
	 * 
	 * Customer URL
	 * In/Out Bound lanes
	 * Seasonal Shipping time
	 * Operations Info
	 * Strategy
	 * Rate Sheet Type
	 * Rotation Cycle
	 * Out/Inbound revenue potential
	 * Required: false
	 * Array: false
	 */
	profileId:string = undefined;
	/**
	 * Actual value for the profile described in the Profile code ID
	 * Required: false
	 * Array: false
	 */
	profileCdValue:string = undefined;
}
/**
 * 
 */
export class CustomerStop {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDeliveryLocation:PickupDeliveryLocation = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notifyOnArrival:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationRemark:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryInstruction:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupInstruction:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	futureInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	dockAccessibleCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	forkliftAccessibleCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	loadUnloadByCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locationTypeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	locationTypeValue:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	deliveryContact:Contact = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupContact:Contact = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	pickupHoursOpenTime:Date[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	pickupHoursCloseTime:Date[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryHoursOpenTime:Date[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	deliveryHoursCloseTime:Date[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	locationBasedTextCustomerProfile:CustomerProfile[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	locationBasedNumberCustomerProfile:CustomerProfile[] = undefined;
}
/**
 * Customer Delivery Requirements(EDSI)
 */
export class DeliveryRequirement {
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique Account Identifier of the Customer in CIS
	 * Required: false
	 * Array: false
	 */
	cisAccountId:string = undefined;
	/**
	 * Unique identifier of the Customer Group to which the CIS Account belongs.
	 * Required: false
	 * Array: false
	 */
	cisCustomerGroupId:string = undefined;
	/**
	 * ADC - Appointment Reschedule Reason
	 * FDD - Final Delivery Date
	 * FDT - Final Delivery Time
	 * POD - Receiver Name
	 * SAD - Scheduled Appointment Date
	 * SAT - Scheduled Appointment Time
	 * Required: false
	 * Array: false
	 */
	deliveryReqCode:string = undefined;
	/**
	 * Description of the Delivery Requirement
	 * Required: false
	 * Array: false
	 */
	deliveryReqDescr:string = undefined;
}
/**
 * The name, address and match information for the customer used in billing.
 */
export class MatchCustomerBillingDetail {
	/**
	 * Name line 1 of the address.
	 * Required: false
	 * Array: false
	 */
	nameLine1:string = undefined;
	/**
	 * Name line 2 of the address.
	 * Required: false
	 * Array: false
	 */
	nameLine2:string = undefined;
	/**
	 * Address line.
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * City Name.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * State Code.
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Country Code.
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Zip Code
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Zip Code + 4
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * Unique identifier for the customer.
	 * Required: false
	 * Array: false
	 */
	custNbr:string = undefined;
	/**
	 * Business identifier for the customer.
	 * Required: false
	 * Array: false
	 */
	madCd:string = undefined;
	/**
	 * Percentage value that indicates how well the billing customer data matched with the stored customer data.
	 * Required: false
	 * Array: false
	 */
	matchScorePercentage:number = undefined;
	/**
	 * Count of times the matched customer has been used in a match previously.
	 * Required: false
	 * Array: false
	 */
	timesMatchedCount:number = undefined;
}
/**
 * 
 */
export class ShipperFreightDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
}
/**
 * 
 */
export class ShipperProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperDimensionsProfile:ShipperDimensionsProfile = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileReset:ProfileReset = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileResetBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cube:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	averageHeightInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	methodUsed:string = undefined;
}
/**
 * Customer SRN requirements
 */
export class SRNRequirement {
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique Account Identifier of the Customer in CIS
	 * Required: false
	 * Array: false
	 */
	cisAccountId:string = undefined;
	/**
	 * Unique identifier of the Customer Group to which the CIS Account belongs.
	 * Required: false
	 * Array: false
	 */
	cisCustomerGroupId:string = undefined;
	/**
	 * Type of Customer location: PnD, Bill To or Corp
	 * Required: false
	 * Array: false
	 */
	cisCustTypeCode:string = undefined;
	/**
	 * This is the edit mask that can be used programatically to check the entered SR# on Bill entry screen
	 * Required: false
	 * Array: false
	 */
	format1:string = undefined;
	/**
	 * This is second optional edit mask. This is specified only when another edit mask as given in Format_1 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format2:string = undefined;
	/**
	 * This is the second optional edit mask. This is specified only when another edit mask as given in Format_2 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format3:string = undefined;
	/**
	 * Textual instructions to the bill entry person. It is displayed on the bill entry screen for the person to make sure the SR Nbr are entered correctly.
	 * Required: false
	 * Array: false
	 */
	instructionText:string = undefined;
	/**
	 * True or False if the SRN is mandatory or not
	 * Required: false
	 * Array: false
	 */
	requiredInd:boolean = undefined;
	/**
	 * Type of Special Reference Number. Eg. SN# or PO# etc
	 * Required: false
	 * Array: false
	 */
	srNbrTypeCode:string = undefined;
	/**
	 * Unique identifier of Customer SRN Requirement
	 * Required: false
	 * Array: false
	 */
	srnRequirementInstId:string = undefined;
}
/**
 * Records the instructions to the Bill Entry Persons. Helps them to correctly enter the bill by highlighting areas they need to pay attention to. 
 */
export class BillingRemark {
	/**
	 * CUST# from CIS. Can be Pickup dlvr or Bill-to type
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * There are two categories of remarks that are accessed from this entity when Biller matches party on the bill.
	 * The first category is for Biller to help correctly enter the bill.
	 * The valid values are :
	 * S - Instructions to Biller for Shipper
	 * C - Instructions to Biller for Consignee
	 * B - Instructions to Biller for Bill-to
	 * The second category of remarks are accesssed from this entity and stored on the shipment database the bill being entered in SHM_REMARKS table.
	 * The valid values are:
	 * H- Instruction to Operations when Hazmat is transported, 
	 * O- Instruction for Pickup and Delivery
	 * Required: false
	 * Array: false
	 */
	remarkTypeCd:BillingRemarkTypeCd = undefined;
	/**
	 * The instruction to the biller. This is displayed after the party on the bill is matched to CIS to get the CIS Cust#. The parties on the bill are Shipper, Consignee, Bill-to. The CUST_TYP_CD denote the type of the party.
	 * Required: false
	 * Array: false
	 */
	billEntryInstruction:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The date the Billing Remark is effective.
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * Desc: The employee id of the Requestor for the add or change.
	 * 05/11/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmplid:string = undefined;
	/**
	 * Desc: The name of the Requestor for the add or change.
	 * 05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrName:string = undefined;
	/**
	 * Desc: The email address of the Requestor for the add or change.
	 * 05/11/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Requestor for the add or change.
	 * 05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * Desc: The name of the Contact for the add or change.
	 * 05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * Desc: The email address of the Contact for the add or change.
	 * 	05/11/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Contact for the add or change.
	 * 05/11/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
}
/**
 * Records the commodities that a customer often ships. 
 * This is currently used when the customer is shipper on a bill of lading.
 * This is to improve productivity of a bill entry person by prompting a list of commodities that they have shipped often in the past.
 */
export class CustCommonCommodity {
	/**
	 * CIS Customer Number for which the commonly shipped commodities are recorded
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * To allow multiple commodities to be recorded for a given customer
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * Required: false
	 * Array: false
	 */
	nmfcCd:string = undefined;
	/**
	 * Identifies the type (class) of commodity being shipped.
	 * Examples: Carpet, Matresses, Computer paper
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500
	 * Required: false
	 * Array: false
	 */
	classTypeCd:CommodityClassCd = undefined;
	/**
	 * Type of packaging the commodity was originally picked up in. 
	 * Examples: Box, Skid, Case, Roll etc.
	 * Required: false
	 * Array: false
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * A textual description of the commodity
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Y - Commodity was added manually by a biller based on the tribal knowlege.
	 * N - Recorded based on analytics of historical data from BI.
	 * Typically when BI sends new updates, the commodities can be added/removed. However, the ones that were manually added are not touched by the import from BI process.
	 * Required: false
	 * Array: false
	 */
	manuallyAddInd:boolean = undefined;
	/**
	 * Y- the commodity can be used as a prompt on the bill entry web page.
	 * N - the commodity is logically deleting. However we do not want to lose the information, in case it needs to be made active again.
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Allows the process that adds new entries to detect duplicate. 
	 * The value is generated by changing DESC_TXT to uppercase, retaining only consonants and numbers. 
	 * During update and insert, trigger automatically generates the value.
	 * Required: false
	 * Array: false
	 */
	descriptionKey:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * This records the rules that are applied to Supplementary Reference# (SR#) when Bill is entered. SR# are supplied by the customer. These are important because unless they are put on the customer Invoice (typically EDI), the customer does not pay us.
 * The rules are specified for Customer and the SR# Type. 
 * The format mask allows program to edit check the format of the entered SR# is as required by the customer.
 */
export class SuppRefRule {
	/**
	 * CIS Cust# for which the rule has been defined.
	 * Required: false
	 * Array: false
	 */
	custNbr:number = undefined;
	/**
	 * SR NBR Type is the one that goes into SHM_SR_NBR. TYP_CD. Example of most common types are: SN#,  PO# BL# BOL etc
	 * Required: false
	 * Array: false
	 */
	suppRefNbrTypeCd:string = undefined;
	/**
	 * This is the edit mask that can be used programatically to check the entered SR# on Bill entry screen. In some cases, the rule could be complex and could not be specified as an edit mask. In that case, this field will be empty. The biller will have to read the Instruction field and make sure the entered SR# is correct.
	 * @ - LETTER
	 * # - NUMBER
	 * N - ALPHANUMERIC
	 * if N is part of the string then specify it in lower case. The alpha in SR# are always to stored in uppercase. e.g. CnW## will be stored CNW01, CNW02 etc.
	 * Required: false
	 * Array: false
	 */
	format1:string = undefined;
	/**
	 * This is second optional edit mask. This is specified only when another edit mask as given in Format_1 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format2:string = undefined;
	/**
	 * This is second optional edit mask. This is specified only when another edit mask as given in Format_2 is present. This is used when there are multiple edit masks and any one of them can be satisfied.
	 * Required: false
	 * Array: false
	 */
	format3:string = undefined;
	/**
	 * Y- Rule is mandatory, if not satisfied, the Order (bill) cannot be submitted.
	 * N- Just recommendation for user to re-check against the paper BOL
	 * Required: false
	 * Array: false
	 */
	requiredInd:boolean = undefined;
	/**
	 * Textual instructions to the bill entry person. It is displayed on the bill entry screen for the person to make sure the SR Nbr are entered correctly.
	 * Required: false
	 * Array: false
	 */
	instruction:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique Id for the Srn Rule.
	 * Required: false
	 * Array: false
	 */
	srnRuleInstId:number = undefined;
	/**
	 * The type of customer location. Valid values are P-Pkup dlvr, B-Bill-to
	 * Required: false
	 * Array: false
	 */
	cisCustomerTypeCd:CustomerTypeCd = undefined;
	/**
	 * This is only applicable for Rules copied from NetSuite during conversion. This is the primary key of the SUPPLEMENTAL_REFERENCE_RULES in NS
	 * Required: false
	 * Array: false
	 */
	nsSupplementalReferenceRuleId:string = undefined;
	/**
	 * DESC: The role the party plays on the bill.  
	 * Valid values are 
	 * INB - Inbound (PND can play this role; can play both PND roles)
	 * OUTB - Outbound (PND can play this role; can play both PND roles)
	 * THIRD - Third Party (only a Bill To can play this role)
	 * INBOTB - Both inbound and outbound
	 * 
	 * 04/16/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	partyRoleCd:PartyRoleCd = undefined;
	/**
	 * Desc: The date the Supp Ref Rule is effective.  Defaults to the date the Supp Ref Rule is created, and may be back dated.  No future dated values are allowed.
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * Desc: The employee id of the Requestor for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmplid:string = undefined;
	/**
	 * Desc: The name of the Requestor for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrName:string = undefined;
	/**
	 * Desc: The email address of the Requestor for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Requestor for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	rqstrPhoneNbr:string = undefined;
	/**
	 * Desc: The name of the Contact for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * Desc: The email address of the Contact for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet  Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactEmailAddress:string = undefined;
	/**
	 * Desc: The phone number of the Contact for the Supp Ref Rule add or change.
	 * 04/16/2018 Cathy Doucet Added for OMS Audit
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * Desc: Another format field is needed
	 * 05/02/2018 Cathy Doucet Added as per Ian A for OMS Audit
	 * Required: false
	 * Array: false
	 */
	format4:string = undefined;
	/**
	 * Desc: Another format field is needed
	 * 05/02/2018 Cathy Doucet Added as per Ian A for OMS Audit
	 * Required: false
	 * Array: false
	 */
	format5:string = undefined;
}
/**
 * 
 */
export class Contact {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keyNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactLastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactFirstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactTitle:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhone:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	email:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxAreaCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	faxNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxuser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxaction:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates a primary customer contact.
	 * Required: false
	 * Array: false
	 */
	primaryInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class InterfaceAcct {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentAcctInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditStatusCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationTypeCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	addressLine2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	postalCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deletedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastCreatedDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	geoCoordinates:LatLong = undefined;
}
/**
 * 
 */
export class ShipperDimensionsProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationZip:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expiryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	modeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mode1Value:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mode2Value:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mode3Value:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	mode4Value:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	significantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	generatorVersionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inpDataBeginDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	inpDataEndDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	measurementsCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	note:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	avgHeightInches:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomerProfile {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileCodeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileCodeTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileNumInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileValueNum:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	profileValueTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ProfileReset {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationZip:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	resetDate:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupDeliveryLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerSuplyNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	simpleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suppNbrReqCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadPrefixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadLocationTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadSuffixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnNatlAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotMatchInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomerOperationsNote {
	/**
	 * Implied FK to CIS_INTRFC_ACCT
	 * Required: false
	 * Array: false
	 */
	customerAcctId:number = undefined;
	/**
	 * Identifies type of note this is.
	 * VALID VALUES
	 * PICKUP_INSTRUCTIONS
	 * DLVRY_INSTRUCTIONS
	 * OPERATIONS_NOTE
	 * Required: false
	 * Array: false
	 */
	noteTypeCd:CustOperationNoteTypeCd = undefined;
	/**
	 * Part of unique identifier.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Text of note
	 * Required: false
	 * Array: false
	 */
	note:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomerOperationsProfile {
	/**
	 * Implied FK to CIS_INTRFC_ACCT
	 * Required: false
	 * Array: false
	 */
	customerAcctId:number = undefined;
	/**
	 * Maximum age of trailer customer will allow on site
	 * .
	 * Required: false
	 * Array: false
	 */
	trailerAgeYear:string = undefined;
	/**
	 * Predicted dwell time, in minutes, for deliveries.
	 * Required: false
	 * Array: false
	 */
	deliveryDwellTimeMinutes:string = undefined;
	/**
	 * Predicted dwell time, in minutes, for pickups.
	 * Required: false
	 * Array: false
	 */
	pickupDwellTimeMinutes:string = undefined;
	/**
	 * Predicted dwell time, in minutes, for equipment stops (hooks and spots)
	 * Required: false
	 * Array: false
	 */
	equipmentDwellTimeMinutes:string = undefined;
	/**
	 * The type of location
	 * Valid Values:
	 * BusinessCommercialBUSINESS/COMMERCIAL
	 * DistributionCenterDISTRIBUTION CENTER
	 * GroceryWareHouseGROCERY WAREHOUSE
	 * ShoppingMallSHOPPING MALL/STRIP MALL
	 * UnversityCollegeUNIVERSITY/COLLEGE
	 * ConstructionSiteCONSTRUCTION/UTILITY SITES: CONSTRUCTION SITE
	 * ConstructionUtilitySiteCONSTRUCTION/UTILITY SITES: UTILITY SITE
	 * GovtMineGOVERNMENT SITES: MINE
	 * GovtGasOilFieldGOVERNMENT SITES: NATURAL GAS OR OIL FIELD
	 * GovtPostOfficeGOVERNMENT SITES: POST OFFICE
	 * GovtMilitaryGOVERNMENT SITES: MILITARY
	 * GovtPrisonCorrectionGOVERNMENT SITES: PRISON/CORRECTION FACILITY
	 * GovtAirportGOVERNMENT SITES: AIRPORT
	 * GovtOtherGOVERNMENT SITES: OTHER GOVERNMENT SITES
	 * PierContainerFreightStationPIERS, WHARFS, CFS: CONTAINER FREIGHT STATION
	 * PierWharfPIERS, WHARFS, CFS: PIER OR WHARF
	 * ResidentialHomeRESIDENTIAL: RESIDENCE
	 * ResidentialApartmentRESIDENTIAL: APARTMENT
	 * ResidentialPlaceofWorshipRESIDENTIAL: PLACE OF WORSHIP
	 * ResidentialSchoolRESIDENTIAL: SCHOOL (K-12)
	 * ResidentialCampRESIDENTIAL: CAMP
	 * ResidentialFarmRESIDENTIAL: FARM
	 * ResidentialPublicStorageRESIDENTIAL: PUBLIC STORAGE FACILITY
	 * ResidentialBusinessRESIDENTIAL: BUSINESS OPERATING WITHIN A PRIVATE RESIDENCE
	 * ExhibitionConvCtrEXHIBITION SITES: EXHIBITION SITE OR CONVENTION CENTER
	 * Required: false
	 * Array: false
	 */
	locationTypeCd:LocationOperationTypeCd = undefined;
	/**
	 * Identifies who loads/unloads trailers at a customer location.
	 * VALID VALUES
	 * CUSTOMER - Customer loads and or unloads our trailers
	 * DSR - DSR loads or unloads our trailers
	 * Required: false
	 * Array: false
	 */
	loadUnloadCd:LoadUnloadIdentifierCd = undefined;
	/**
	 * Indicates the size of the trailer required at this site.
	 * VALID VALUES
	 * STRAIGHT - Straight truck/box truck required
	 * PUP - Pup or smaller required
	 * VANOK - an OK even in a geographic area otherwise restricted to PUPs
	 * Required: false
	 * Array: false
	 */
	equipmentSizeCd:EquipmentSizeCd = undefined;
	/**
	 * Y indicates that the customer receives freight that can be stacked in city operations.
	 * Required: false
	 * Array: false
	 */
	deliveryStackableInd:boolean = undefined;
	/**
	 * Y indicates that the customer ships freight that can be stacked in city operations.
	 * Required: false
	 * Array: false
	 */
	pickupStackableInd:boolean = undefined;
	/**
	 * Indicates that the customer requires their freight to be trapped for full trailer or specific day delivery.
	 * Required: false
	 * Array: false
	 */
	trapRequiredInd:boolean = undefined;
	/**
	 * Indicates if customer has a forklift or not.
	 * Required: false
	 * Array: false
	 */
	forkliftInd:boolean = undefined;
	/**
	 * Indicates if a liftgate is required at this site.
	 * Required: false
	 * Array: false
	 */
	liftgateRequiredInd:boolean = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates Customer has a freight loading/unloading dock.  Added 06/22/2020
	 * Required: false
	 * Array: false
	 */
	hasDockInd:boolean = undefined;
	/**
	 * Notice of Arrival requirement for Customer.  Added 06/22/2020
	 * Required: false
	 * Array: false
	 */
	noaInd:boolean = undefined;
	/**
	 * Customer ships high volume shipments.  Added 06/22/2020
	 * Required: false
	 * Array: false
	 */
	volumeShipperInd:boolean = undefined;
	/**
	 * Customer requests a Delivery Manifest in lieu of delivery receipt.  Added 06/22/2020
	 * Required: false
	 * Array: false
	 */
	dmanInLieuOfDoorInd:boolean = undefined;
	/**
	 * Indicates Customer requires an appointment for shipment deliveries.  Added 08/18/2020 LISG-6893
	 * Usage:
	 * In order entry, this can be used to auto-prompt Biller, to add the appointment accessorial for the service.
	 * In PnD, helps planner or auto routing to put these shipments on an appointment route.
	 * Improves route optimization and revenue related to appointment services.
	 * Required: false
	 * Array: false
	 */
	appointmentRequiredInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomerOperationsSchedule {
	/**
	 * Implied FK to CIS_INTRFC_ACCT
	 * Required: false
	 * Array: false
	 */
	customerAcctId:number = undefined;
	/**
	 * A schedule can be defined for these types of operations.
	 * VALID VALUES
	 * PICKUP_SHIPPING - Pickup / Shipping hours
	 * DELIVERY_RECEIVING - Delivery / Receiving hours
	 * GENERAL_OPERATIONS - General operations hours
	 * Required: false
	 * Array: false
	 */
	operationTypeCd:CustOperationHoursTypeCd = undefined;
	/**
	 * Effective start date of this record
	 * Required: false
	 * Array: false
	 */
	startDate:string = undefined;
	/**
	 * If there are schedule hour differences on differnet days, then this number allows them to be defined.  For example, Schedule 1 defines hours for days 1-5, and Schedule 2 defines hours for day 6.
	 * Required: false
	 * Array: false
	 */
	scheduleNbr:string = undefined;
	/**
	 * Effect end date of this record.
	 * Required: false
	 * Array: false
	 */
	endDate:string = undefined;
	/**
	 * Start time (customer's local start time).
	 * Required: false
	 * Array: false
	 */
	startTime:string = undefined;
	/**
	 * End time
	 * Required: false
	 * Array: false
	 */
	endTime:string = undefined;
	/**
	 * Schedule is followed on Sundays
	 * Required: false
	 * Array: false
	 */
	sundayInd:boolean = undefined;
	/**
	 * Schedule is followed on Mondays
	 * Required: false
	 * Array: false
	 */
	mondayInd:boolean = undefined;
	/**
	 * Schedule is followed on Tuesdays
	 * Required: false
	 * Array: false
	 */
	tuesdayInd:boolean = undefined;
	/**
	 * Schedule is followed on Wednesdays
	 * Required: false
	 * Array: false
	 */
	wednesdayInd:boolean = undefined;
	/**
	 * Schedule is followed on Thursdays
	 * Required: false
	 * Array: false
	 */
	thursdayInd:boolean = undefined;
	/**
	 * Schedule is followed on Fridays
	 * Required: false
	 * Array: false
	 */
	fridayInd:boolean = undefined;
	/**
	 * Schedule is followed on Saturdays
	 * Required: false
	 * Array: false
	 */
	saturdayInd:boolean = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multiple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Same value for event id is assigned to various rows from different tables that form a single database transactions. 
	 * Multiple database transactions could make up one business transaction, which is indicated by correlation_id
	 * In other words, this groups multiple rows across various tables for a single database transaction.
	 * Typically this is used to publish a message to a queue for consumption or a workflow.
	 * Required: false
	 * Array: false
	 */
	scoEventId:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
export class AcctBillToDetail_AcctId extends AcctId {
	/**
	 * Required: false
	 * Array: false
	 */
	acctNameLine2:string = undefined;
	/**
	 * Credit Service Authorization Number
	 * Required: false
	 * Array: false
	 */
	creditSvcAuthNbr:string = undefined;
	/**
	 * Credit Service Limit Amount
	 * Required: false
	 * Array: false
	 */
	creditSvcLimit:MonetaryAmount = undefined;
	/**
	 * Service Account PPD
	 * Required: false
	 * Array: false
	 */
	svcAcctPpd:string = undefined;
	/**
	 * Service Account COL
	 * Required: false
	 * Array: false
	 */
	svcAcctCol:string = undefined;
	/**
	 * Service Recipient Name
	 * Required: false
	 * Array: false
	 */
	svcRcpntName:string = undefined;
}

/**
 * Query parameters for getAcctBillToLocationDetail
 */
export class GetAcctBillToLocationDetailQuery {
	acctInstId: string = undefined;
	acctMadCd: string = undefined;
}

/**
 * Query parameters for getCisCustomerHierarchy
 */
export class GetCisCustomerHierarchyQuery {
	madCd: string = undefined;
	legacyCisCustNbr: number = undefined;
	customerHierarchyType: string = undefined;
}

/**
 * Query parameters for listAcctBasicDetails
 */
export class ListAcctBasicDetailsQuery {
	acctInstId: string[] = undefined;
	acctName: string = undefined;
	addressLine1: string = undefined;
	cityName: string = undefined;
	stateCd: string = undefined;
	countryCd: string = undefined;
	postalCd: string = undefined;
	acctPartyRole: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listCorpCustAcctHierarchyChildren
 */
export class ListCorpCustAcctHierarchyChildrenQuery {
	acctPartyRole: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getCustomerProfile
 */
export class GetCustomerProfileQuery {
	profileTypeCd: string = undefined;
}

/**
 * Query parameters for getShipperDimensionProfile
 */
export class GetShipperDimensionProfileQuery {
	profileCategoryCd: string = undefined;
	effectiveDate: string = undefined;
	significantInd: string = undefined;
	startingDestinationZip: string = undefined;
}

/**
 * Query parameters for getRelatedBillToForCustomer
 */
export class GetRelatedBillToForCustomerQuery {
	customerNbr: number = undefined;
	customerMadCd: string = undefined;
	customerTypeCd: CustomerTypeCd = undefined;
	billDate: string = undefined;
	chargeToCd: ChargeToCd = undefined;
	invoiceTypeCd: InvoiceTypeCd = undefined;
}

/**
 * Query parameters for getSendToPartyPaymentTerm
 */
export class GetSendToPartyPaymentTermQuery {
	customerNbr: number = undefined;
	customerMadCd: string = undefined;
	customerTypeCd: CustomerTypeCd = undefined;
	billDate: string = undefined;
	chargeToCd: ChargeToCd = undefined;
	invoiceTypeCd: InvoiceTypeCd = undefined;
}

/**
 * Query parameters for listCustomerGroupsForLocation
 */
export class ListCustomerGroupsForLocationQuery {
	customerTypeCd: CustomerTypeCd = undefined;
	groupType: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getCustomerGroupDetails
 */
export class GetCustomerGroupDetailsQuery {
	custGroupInstId: number = undefined;
	groupMadCd: string = undefined;
	effDate: string = undefined;
}

/**
 * Query parameters for updateGeoCoordinatesForCust
 */
export class UpdateGeoCoordinatesForCustQuery {
	areaInstId: number = undefined;
}

/**
 * Query parameters for listCustCommonBols
 */
export class ListCustCommonBolsQuery {
	shipperCustNbr: number = undefined;
	consigneeCustNbr: number = undefined;
	billToCustNbr: number = undefined;
	chargeToCd: ChargeToCd = undefined;
	effectiveDate: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSRNRules
 */
export class ListSRNRulesQuery {
	customerNbr: number = undefined;
	customerGroupInstId: number = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listBillingRemarks
 */
export class ListBillingRemarksQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}


/**
 * Path parameters for getAcctBasicDetails
 */
export class GetAcctBasicDetailsPath {
	acctInstId: string = undefined;
}

/**
 * Path parameters for getCustomerContacts
 */
export class GetCustomerContactsPath {
	custAcctNbr: number = undefined;
}

/**
 * Path parameters for listCorpCustAcctHierarchyChildren
 */
export class ListCorpCustAcctHierarchyChildrenPath {
	acctInstId: string = undefined;
}

/**
 * Path parameters for maintainCustProfile
 */
export class MaintainCustProfilePath {
	acctInstId: string = undefined;
}

/**
 * Path parameters for listCustProfileCodeByType
 */
export class ListCustProfileCodeByTypePath {
	profileTypeCd: string = undefined;
}

/**
 * Path parameters for getCustomerProfile
 */
export class GetCustomerProfilePath {
	custAcctNbr: number = undefined;
}

/**
 * Path parameters for getShipperDimensionProfile
 */
export class GetShipperDimensionProfilePath {
	shipperId: number = undefined;
}

/**
 * Path parameters for getShipperProfileFreightCube
 */
export class GetShipperProfileFreightCubePath {
	shipperId: number = undefined;
}

/**
 * Path parameters for updateCisCustomerContacts
 */
export class UpdateCisCustomerContactsPath {
	customerAcctId: number = undefined;
}

/**
 * Path parameters for updateCisCustomerProfile
 */
export class UpdateCisCustomerProfilePath {
	customerAcctId: number = undefined;
}

/**
 * Path parameters for getCustomerLocation
 */
export class GetCustomerLocationPath {
	custAcctId: string = undefined;
}

/**
 * Path parameters for determineRestrictedBillTo
 */
export class DetermineRestrictedBillToPath {
	billToCustNbr: number = undefined;
}

/**
 * Path parameters for listCustomerGroupsForLocation
 */
export class ListCustomerGroupsForLocationPath {
	customerNbr: string = undefined;
}

/**
 * Path parameters for listCustomerInvcTypeAddresses
 */
export class ListCustomerInvcTypeAddressesPath {
	customerNbr: number = undefined;
}

/**
 * Path parameters for updateGeoCoordinatesForCust
 */
export class UpdateGeoCoordinatesForCustPath {
	customerId: string = undefined;
}

/**
 * Path parameters for getCustomerLocationOperationsProfile
 */
export class GetCustomerLocationOperationsProfilePath {
	custAcctNbr: number = undefined;
}

/**
 * Path parameters for getCustomerStopDetail
 */
export class GetCustomerStopDetailPath {
	customerNbr: number = undefined;
}

/**
 * Path parameters for updateSRNRules
 */
export class UpdateSRNRulesPath {
	srnRuleInstId: number = undefined;
}

/**
 * Path parameters for deleteSRNRules
 */
export class DeleteSRNRulesPath {
	srnRuleInstId: number = undefined;
}

/**
 * Path parameters for getSRNRule
 */
export class GetSRNRulePath {
	srnRuleInstId: number = undefined;
}

/**
 * Path parameters for updateBillingRemarks
 */
export class UpdateBillingRemarksPath {
	custNbr: number = undefined;
	remarkTypeCd: BillingRemarkTypeCd = undefined;
}

/**
 * Path parameters for deleteBillingRemarks
 */
export class DeleteBillingRemarksPath {
	custNbr: number = undefined;
	remarkTypeCd: BillingRemarkTypeCd = undefined;
}

/**
 * Path parameters for listBillingRemarks
 */
export class ListBillingRemarksPath {
	custNbr: number = undefined;
}

/**
 * Path parameters for getBillingRemark
 */
export class GetBillingRemarkPath {
	custNbr: number = undefined;
	remarkTypeCd: BillingRemarkTypeCd = undefined;
}

